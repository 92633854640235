<fh-mini-map [zoomLevel]="3" [showHeader]="true" [headerText]="'Earthquake Map Location'"></fh-mini-map>
<div *ngIf="isInteractionVisible(constants.SHAKEMAP) || isInteractionVisible(constants.FELT_GRID)" class="header">
  <img [src]="showShakeMapLegend ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" alt="Legend"
       class="toggle-icon" (click)="toggleShowShakeMapLegend()"/>{{getLegendText()}} Legend
</div>
<div *ngIf="(isInteractionVisible(constants.SHAKEMAP) || isInteractionVisible(constants.FELT_GRID)) && !showMiniMapSpinner" class="shakemap-legend" [@animationCommonEnter500]>
  <img *ngIf="showShakeMapLegend" src="assets/shakemap_legend.png" alt="ShakeMap Legend">
</div>
<div *ngIf="shakeMap?.shakemap_version && isInteractionVisible(constants.SHAKEMAP) && showShakeMapLegend" class="text-center" [@animationCommonEnter500]>
  <p [@animationCommonEnter300Leave300]>ShakeMap Version {{shakeMap?.shakemap_version}} Source {{shakeMap?.shakemap_source}}</p>
</div>
<div class="text-center" style="margin-bottom: 5px">
  <button id="shakeMapButton" class="btn btn-ga" (click)="toggleShakeMap()" *ngIf="isInteractionEnabled(constants.SHAKEMAP)">
    {{interactions.get(constants.SHAKEMAP).visible ? 'Hide ShakeMap' : 'ShakeMap'}}
  </button>
  <button id="feltGridButton" class="btn btn-ga" (click)="toggleFeltGrid()" *ngIf="isInteractionEnabled(constants.FELT_GRID)">
    {{interactions.get(constants.FELT_GRID).visible ? 'Hide Felt Grid' : 'Felt Grid'}}
  </button>
  <button id="feltReportsButton" class="btn btn-ga" (click)="toggleFeltReports()" *ngIf="isInteractionEnabled(constants.FELT_REPORTS)">
    {{interactions.get(constants.FELT_REPORTS).visible ? 'Hide Felt Reports' : 'Felt Reports'}}
  </button>
  <button id="locationUncertaintyButton" class="btn btn-ga" (click)="toggleLocationUncertainty()" *ngIf="isInteractionEnabled(constants.LOCATION_UNCERTAINTY) && isFeaturePreSeiscomp">
    {{interactions.get(constants.LOCATION_UNCERTAINTY).visible ? 'Hide Location Uncertainty' : 'Location Uncertainty'}}
  </button>
</div>
<div #bottom></div>

import { Pixel } from 'ol/pixel';

export interface ShakeMap {
  event_id: string;
  event_depth: number;
  event_latitude: number;
  event_longitude: number;
  event_magnitude: number;
  event_magnitude_type: string;
  event_origin_time: string;
  event_published_version: number;
  felt_grid_enabled: 'Y' | 'N';
  shakemap_enabled: 'Y' | 'N';
  shakemap_extent: string;
  shakemap_version: number;
  shakemap_disclaimer: 'Y' | 'N';
  shakemap_source: string;
  traces_enabled: 'Y' | 'N';
}

export interface ShakemapEventType {
  pixel: Pixel;
  event_id: string;
  depth: number;
  latitude: number;
  longitude: number;
  magnitude: number;
  magnitude_type: string;
  origin_time: string;
  published_version: number;
  source: string;
}

export const SHAKEMAP_DEFAULT: ShakeMap = {
  event_id: null,
  event_depth: null,
  event_latitude: null,
  event_longitude: null,
  event_magnitude: null,
  event_magnitude_type: null,
  event_origin_time: null,
  event_published_version: null,
  felt_grid_enabled: 'N',
  shakemap_enabled: 'N',
  shakemap_extent: null,
  shakemap_version: null,
  shakemap_disclaimer: 'N',
  shakemap_source: null,
  traces_enabled: 'N',
};

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddressLocation, FeltReport } from './felt.report';
import * as moment from 'moment';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocationService } from '@shared/services/location/location.service';
import { GeocodeAddressArray, GeocodeAddressCandidateType, GeocodingResultType, ReverseGeocodingResultType } from '@shared/services/location/location.types';
import { ENVIRONMENT } from 'flying-hellfish-common';

@Injectable()
export class FeltReportService {
  private apiGatewayKey: string = this.environment.apiGateway.key;

  report: FeltReport;

  constructor(private http: HttpClient, private locationService: LocationService, @Inject(ENVIRONMENT) private environment: any) {
  }

  // Call the ArcGIS World Geocoding Service to determine an address at a particular longitude and latitude
  reverseGeocode(location: Partial<GeolocationCoordinates>): Observable<ReverseGeocodingResultType | null> {
    return this.locationService.positionToLocationText(location.longitude, location.latitude);
  }

  // Call the ArcGIS World Geocoding Service to determine an address
  geocodeAddress(addressLocation: AddressLocation): Observable<GeocodingResultType> {
    let queryAddress: string = '';
    if (addressLocation.address) {
      queryAddress = addressLocation.address + ' ';
    }

    if (addressLocation.suburb) {
      queryAddress += addressLocation.suburb + ' ';
    }

    if (addressLocation.state) {
      queryAddress += this.getStateFromAbbreviation(addressLocation.state) + ' ';
    }

    if (addressLocation.postcode) {
      queryAddress += addressLocation.postcode;
    }

    return this.locationService.locationTextToPosition(queryAddress);
  }

  // Get address suggestions with arcgis
  getAddressSuggestions(inputAddress: string): Observable<GeocodeAddressCandidateType[]> {
    let url: string = this.environment.feltReport.getAddressCandidatesUrl;
    // Filter based on address
    if (inputAddress) {
      url += encodeURIComponent(inputAddress);
    }
    return this.http.get<GeocodeAddressArray>(url).pipe(
      map((response) => {
        return response.candidates;
      })
    );
  }

  // Calls the AWS API to send an email and store the felt report in the database
  submitFeltReport(feltReport: FeltReport): Observable<any> {
    const body: string = JSON.stringify({
      report: this.getProcessedFeltReport(feltReport)
    });

    const httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application',
        'x-api-key': this.apiGatewayKey
      })
    };

    return this.http.post(this.environment.feltReport.storeApi, body, httpOptions);
  }

  // Check if the other fields have been populated and if so update the feltReport object
  getProcessedFeltReport(feltReport: FeltReport): FeltReport {
    this.report = JSON.parse(JSON.stringify(feltReport));

    if (feltReport.situation === 'Other') {
      this.report.situation = feltReport.situationOther;
    }

    if (feltReport.response === 'Other') {
      this.report.response = feltReport.responseOther;
    }

    return this.report;
  }

  // Returns a formatted the email body
  getEmailBody(feltReport: FeltReport): string {
    let body: string;

    body = 'felt: ' + this.format(feltReport.felt);
    const dateTime: moment.Moment | Date = feltReport.epicentralDateTime ? moment.utc(feltReport.epicentralDateTime) : feltReport.dateTime;
    body += 'date_time: ' + this.format(moment(dateTime).format('DD-MM-YYYY HH:mm'));

    if (feltReport.situation === 'Other') {
      body += 'situation: ' + this.format(feltReport.situationOther);
    } else {
      body += 'situation: ' + this.format(feltReport.situation);
    }

    body += 'sleep: ' + this.format(feltReport.sleep);
    body += 'othersFelt: ' + this.format(feltReport.othersFelt);
    body += 'motion: ' + this.format(feltReport.motion);
    body += 'reaction: ' + this.format(feltReport.reaction);

    if (feltReport.response === 'Other') {
      body += 'response: ' + this.format(feltReport.responseOther);
    } else {
      body += 'response: ' + this.format(feltReport.response);
    }

    body += 'stand: ' + this.format(feltReport.stand);
    body += 'swing: ' + this.format(feltReport.swing);
    body += 'noise: ' + this.format(feltReport.noise);
    body += 'shelf: ' + this.format(feltReport.shelf);
    body += 'picture: ' + this.format(feltReport.picture);
    body += 'furniture: ' + this.format(feltReport.furniture);
    body += 'appliance: ' + this.format(feltReport.appliance);
    body += 'wallsDamaged: ' + this.format(feltReport.wallsFences);
    body += 'damage: ' + this.format(feltReport.damage);
    body += 'address: ' + this.format(feltReport.address);
    body += 'suburb: ' + this.format(feltReport.suburb);
    body += 'state: ' + this.format(feltReport.state);
    body += 'postcode: ' + this.format(feltReport.postcode);
    body += 'comments: ' + this.format(feltReport.comments);
    body += 'name: ' + this.format(feltReport.name);
    body += 'phone: ' + this.format(feltReport.phone);
    body += 'email: ' + this.format(feltReport.email);
    body += 'latitude: ' + this.format(feltReport.latitude);
    body += 'longitude: ' + this.format(feltReport.longitude);
    body += 'privacyConsent: ' + this.format(feltReport.privacyConsent);

    return body;
  }

  // Formats the value
  format(input: any): string {
    let formattedString: string;

    if (input !== undefined) {
      formattedString = '\'' + input + '\'' + '\n';
    } else {
      formattedString = '\'\'\n';
    }

    return formattedString;
  }

  // Returns the states abbreviation
  getStateAbbreviation(state: string): string {
    switch (state) {
      case 'Australian Capital Territory':
        return 'ACT';
      case 'New South Wales':
        return 'NSW';
      case 'Northern Territory':
        return 'NT';
      case 'Queensland':
        return 'QLD';
      case 'South Australia':
        return 'SA';
      case 'Tasmania':
        return 'TAS';
      case 'Victoria':
        return 'VIC';
      case 'Western Australia':
        return 'WA';
      default:
        return '';
    }
  }

  // Returns the states abbreviation
  getStateFromAbbreviation(state: string): string {
    if (!state) {
      return '';
    }
    switch (state.trim().toUpperCase()) {
      case'ACT':
        return 'Australian Capital Territory';
      case'NSW':
        return 'New South Wales';
      case'NT':
        return 'Northern Territory';
      case'QLD':
        return 'Queensland';
      case'SA':
        return 'South Australia';
      case'TAS':
        return 'Tasmania';
      case'VIC':
        return 'Victoria';
      case'WA':
        return 'Western Australia';
      default:
        return '';
    }
  }

  // Set the weights for the intensity map
  setWeightsForIntensityIndex(feltReport: FeltReport): void {
    const feltWeights: any = this.environment.feltReport.weights.feltIndex;
    const othersFeltWeights: any = this.environment.feltReport.weights.othersFeltIndex;
    const motionWeights: any = this.environment.feltReport.weights.motionIndex;
    const reactionWeights: any = this.environment.feltReport.weights.reactionIndex;
    const standWeights: any = this.environment.feltReport.weights.standIndex;
    const shelfWeights: any = this.environment.feltReport.weights.shelfIndex;
    const pictureWeights: any = this.environment.feltReport.weights.pictureIndex;
    const furnitureWeights: any = this.environment.feltReport.weights.furnitureIndex;
    const damageWeights: any = this.environment.feltReport.weights.damageIndex;

    const updateWeights: (weights: any, reportKey: string) => void = (weights, reportKey) => {
      for (const weight of weights) {
        if (weight.key === feltReport[reportKey]) {
          feltReport[reportKey + 'Index'] = weight.value;
        }
      }
    };

    updateWeights(feltWeights, 'felt');
    updateWeights(othersFeltWeights, 'othersFelt');
    updateWeights(motionWeights, 'motion');
    updateWeights(reactionWeights, 'reaction');
    updateWeights(standWeights, 'stand');
    updateWeights(shelfWeights, 'shelf');
    updateWeights(pictureWeights, 'picture');
    updateWeights(furnitureWeights, 'furniture');
    updateWeights(damageWeights, 'damage');
  }
}

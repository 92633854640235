import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, NgZone, OnChanges, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapService } from '../../map/map.service';
import { FeatureType } from '../../types/feature.type';
import { Glossary } from '@shared/glossary';
import { AnimationCommon, AutoRefreshCommonService, BoundsCommon, BoundsCommonType, DeviceCommonService, ENVIRONMENT, GeometryCommonService, MiniMapCommonComponent, SidePanelCommonService } from 'flying-hellfish-common';
import { QuakeDetailsService } from './quake.details.service';
import { ScaleQuantize, scaleQuantize } from 'd3-scale';
import { rgb, RGBColor } from 'd3-color';
import { ShakeMap } from '../../types/shakemap.type';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Group, Layer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import { Point } from 'ol/geom';
import { unByKey } from 'ol/Observable';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { transform, transformExtent } from 'ol/proj';
import { Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import Feature, { FeatureLike } from 'ol/Feature';
import { EventsKey } from 'ol/events';
import { Select } from 'ol/interaction';
import { Cluster } from 'ol/source';
import LayerGroup from 'ol/layer/Group';

export class InteractionConstants {
  public static readonly FELT_REPORTS: string = 'reports';
  public static readonly LOCATION_UNCERTAINTY: string = 'location';
  public static readonly SHAKEMAP: string = 'shakemap';
  public static readonly FELT_GRID: string = 'feltGrid';
}

@Component({
  selector: 'ga-quake-details-map',
  templateUrl: 'quake.details.map.component.html',
  styleUrls: ['quake.details.map.component.css'],
  preserveWhitespaces: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    AnimationCommon.animationCommonEnter300Leave300,
    AnimationCommon.animationCommonEnter500
  ]
})
export class QuakeDetailsMapComponent implements OnChanges, AfterViewInit, OnDestroy {
  static feltReportMapVectorLayer: VectorLayer<VectorSource>;
  static feltReportMiniMapVectorLayer: VectorLayer<VectorSource>;
  static feltReportFeatureCount: number;

  @ViewChildren(MiniMapCommonComponent) miniMapComponents: QueryList<MiniMapCommonComponent>;
  @ViewChild('legend', { read: ElementRef }) legendElementRef: ElementRef;
  @ViewChild('bottom', { read: ElementRef }) bottomElementRef: ElementRef;

  private earthQuakeFeltReportsWFSUrl: string = this.environment.serviceUrls.earthQuakeFeltReportsWFS;
  private geoJsonFormatter: GeoJSON = new GeoJSON();
  private subscriptions: Subscription = new Subscription();

  datumProjection: string = this.environment.datumProjection;
  displayProjection: string = this.environment.displayProjection;
  zoomLevel: number = this.environment.quakeDetails.zoomLevel;
  stationsURL: string = this.environment.serviceUrls.earthQuakeStationsWFS;
  stationsVectorLayer: VectorLayer<VectorSource>;
  earthquakeVectorLayer: VectorLayer<VectorSource>;
  glossary: Glossary;
  width: number;
  isFeaturePreSeiscomp: boolean = false;
  feltReportMapInteraction: Select;
  feltReportMiniMapInteraction: Select;
  hoursSinceReport: number = 24;
  showMiniMapSpinner: boolean = false;
  feltGridClickListenerKey: EventsKey;
  shakemapClickListenerKey: EventsKey;
  showShakeMapLegend: boolean = true;
  lastBounds: BoundsCommonType;

  interactions: Map<string, { enabled: boolean, visible: boolean, mapLayer?: VectorLayer<VectorSource>, miniMapLayer?: VectorLayer<VectorSource>, mapLayerGroup?: LayerGroup, miniMapLayerGroup?: LayerGroup, autoRefreshOperator?: string }> = new Map();
  constants: typeof InteractionConstants = InteractionConstants;

  @Input() feature: FeatureType;
  @Input() shakeMap: ShakeMap;

  // Calculates the radius for cluster
  static calculateClusterInfo(): void {
    QuakeDetailsMapComponent.feltReportFeatureCount = 0;
    const mapFeatures: any = QuakeDetailsMapComponent.feltReportMapVectorLayer.getSource().getFeatures();
    const miniMapFeatures: any = QuakeDetailsMapComponent.feltReportMiniMapVectorLayer.getSource().getFeatures();

    for (const feature of mapFeatures) {
      feature.set('radius', 25);
    }

    for (const feature of miniMapFeatures) {
      feature.set('radius', 25);
    }
  }

  constructor(private mapService: MapService, private ref: ChangeDetectorRef, private deviceCommonService: DeviceCommonService,
              private sidePanelCommonService: SidePanelCommonService, private quakeDetailsService: QuakeDetailsService, private geometryCommonService: GeometryCommonService,
              private autoRefreshCommonService: AutoRefreshCommonService, private zone: NgZone, @Inject(ENVIRONMENT) private environment: any) {
    this.mapService.setZone(zone);
    this.glossary = new Glossary();
    this.initialiseInteractions();

    // Update the map to match the new base layer
    this.subscriptions.add(this.mapService.baseLayerChangedEmitter.subscribe((data) => {
      for (const layer of this.mapService.mapLayers) {
        if (layer.id === data && layer.isBaseLayer) {
          // Set the base layer visibility to true
          layer.visible = true;
        } else if (layer.isBaseLayer) {
          // Set the base layer visibility to false
          layer.visible = false;

          // Set the child layer visibility to false
          if (layer.childId.length > 0) {
            for (const overlay of this.mapService.mapLayers) {
              if (overlay.id === layer.childId) {
                overlay.visible = false;
              }
            }
          }
        }
      }

      this.ref.markForCheck();
    }));

    this.subscriptions.add(this.sidePanelCommonService.rightMenuEmitter.subscribe((data) => {
      // Clean up the layers on the map
      if (data.lastActiveToolId === 'recentQuakes' || data.lastActiveToolId === 'identify') {
        this.reset();
      }
    }));
  }

  ngOnChanges(): void {
    this.reset();

    // Check if the event has felt reports
    this.quakeDetailsService.getFeltReportsForEvent(this.feature.event_id).pipe(first()).subscribe({
      next: (response) => {
        this.interactions.get(InteractionConstants.FELT_REPORTS).enabled = response.totalFeatures > 0;

        this.ref.markForCheck();
      },
      error: (error) => {
        console.error(error);
      }
    });

    // Reset the hours filter
    this.hoursSinceReport = 24;

    // Check if creation date is before Seiscomp EQ@GA AWS release date
    this.isFeaturePreSeiscomp = this.quakeDetailsService.isFeaturePreSeiscomp(this.feature);

    // Check if the event has location uncertainty
    if (this.hasLocationUncertainty() && this.quakeDetailsService.isFeaturePreSeiscomp(this.feature)) {
      this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).enabled = true;
    }

    // Check if the event has Shakemap
    this.interactions.get(this.constants.SHAKEMAP).enabled = this.shakeMap.shakemap_enabled === 'Y';

    // Check if the event has a Felt Grid
    this.interactions.get(this.constants.FELT_GRID).enabled = this.shakeMap.felt_grid_enabled === 'Y';

    if (this.earthquakeVectorLayer) {
      this.earthquakeVectorLayer.getSource().clear();
    }
    this.createEarthquakeLayer();
  }

  ngAfterViewInit(): void {
    this.createEarthquakeLayer();

    if (this.deviceCommonService.isMobile()) {
      this.showShakeMapLegend = false;
    }
  }

  // Create the earthquake layer
  createEarthquakeLayer(): void {
    if (this.miniMapComponents) {
      if (this.miniMapComponents.length > 0) {
        this.earthquakeVectorLayer = new VectorLayer({
          source: new VectorSource({
            features: [this.getEarthquakeFeature()]
          })
        });
        const coordinate: Coordinate = transform([this.feature.longitude, this.feature.latitude], this.environment.displayProjection, this.environment.datumProjection);
        this.miniMapComponents.first.miniMapService.setMapPosition(coordinate[1], coordinate[0], 6, false);
        this.zone.runOutsideAngular(() => {
          this.miniMapComponents.first.miniMapService.mapInstance.addLayer(this.earthquakeVectorLayer);
        });
      }
    }
  }

  // Create the star for the Shake Map feature
  private getEarthquakeFeature(): Feature {
    const epicentre: Feature = new Feature({
      geometry: new Point([this.feature.longitude, this.feature.latitude])
    });

    epicentre.getGeometry().transform(this.environment.displayProjection, this.environment.datumProjection);

    epicentre.setStyle(new Style({
      image: new Circle({
        fill: new Fill({
          color: this.getEarthquakeColour(this.feature)
        }),
        stroke: new Stroke({
          color: this.getEarthquakeColour(this.feature),
          width: this.width
        }),
        radius: 10
      })
    }));

    return epicentre;
  }

  // Initialise all the interactions to false
  initialiseInteractions(): void {
    this.interactions.set(InteractionConstants.FELT_REPORTS, {
      enabled: false,
      visible: false
    });
    this.interactions.set(InteractionConstants.LOCATION_UNCERTAINTY, {
      enabled: false,
      visible: false
    });
    this.interactions.set(InteractionConstants.SHAKEMAP, {
      enabled: false,
      visible: false
    });
    this.interactions.set(InteractionConstants.FELT_GRID, {
      enabled: false,
      visible: false
    });
  }

  // Only display the location uncertainty button if the feature has the required parameters
  hasLocationUncertainty(): boolean {
    let isValid: boolean = false;
    if (this.feature.latitude != null && this.feature.max_horizontal_uncertainty != null && this.feature.min_horizontal_uncertainty != null && this.feature.azimuth_horizontal_uncertainty != null &&
      this.feature.latitude !== 0 && this.feature.max_horizontal_uncertainty !== 0 && this.feature.min_horizontal_uncertainty !== 0 && this.feature.azimuth_horizontal_uncertainty !== 0) {
      isValid = true;
    }
    return isValid;
  }

  // Displays the location uncertainty on the map
  toggleLocationUncertainty(): void {
    this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).visible = !this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).visible;

    if (this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).visible) {
      // Calculate the points to display on the map
      const points: any[] = this.calculateEllipse(this.feature.longitude, this.feature.latitude, this.feature.max_horizontal_uncertainty,
        this.feature.min_horizontal_uncertainty, this.feature.azimuth_horizontal_uncertainty, 40);

      // Convert the points from 4326 to 3857
      const transformedPoints: Coordinate[] = [];
      for (const element of points) {
        transformedPoints.push(transform([element[0], element[1]], this.displayProjection, this.datumProjection));
      }

      // Create a GeoJSON object
      const geoJsonObject: any = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: this.datumProjection
          }
        },
        features: [{
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [transformedPoints]
          }
        }]
      };

      // Create the vector layer
      this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).mapLayer = new VectorLayer({
        source: new VectorSource({
          features: (new GeoJSON()).readFeatures(geoJsonObject)
        }),
        style: new Style({
          stroke: new Stroke({
            color: 'rgba(255, 51, 51, 1)',
            width: 3
          }),
          fill: new Fill({
            color: 'rgba(255, 51, 51, 0.3)'
          })
        }),
        zIndex: 80
      });

      const bounds: BoundsCommonType = this.mapService.getBoundariesForCoordinates(transformedPoints);
      this.mapService.zoomToBoundsWithPadding(bounds, 2000, [100, this.sidePanelCommonService.openPanelWidth, 150, 50]);

      // Add the layer to the map
      setTimeout(() => {
        this.mapService.addLayerToMap(this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).mapLayer);
      }, this.getTimeoutForLastBounds(bounds, 500));
    } else {
      this.mapService.removeLayer(this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).mapLayer);
    }
  }

  // Displays or hides the ShakeMap on the map
  async toggleShakeMap(): Promise<void> {
    this.interactions.get(InteractionConstants.SHAKEMAP).visible = !this.interactions.get(InteractionConstants.SHAKEMAP).visible;

    if (this.interactions.get(InteractionConstants.SHAKEMAP).visible) {
      await this.addShakemap();
    } else {
      this.removeShakemap();
    }
  }

  // Adds shakepmap to the map
  async addShakemap(): Promise<void> {
    this.showMiniMapSpinner = true;
    this.interactions.get(InteractionConstants.SHAKEMAP).mapLayerGroup = new Group();
    this.interactions.get(InteractionConstants.SHAKEMAP).miniMapLayerGroup = new Group();

    const extent: Extent = transformExtent(
      this.shakeMap.shakemap_extent.split(',').map(x => +x) as Extent,
      this.environment.displayProjection,
      this.environment.datumProjection
    );

    // Add the ShakeMap layer to the layer group
    this.interactions.get(InteractionConstants.SHAKEMAP).mapLayerGroup.getLayers().push(
      await this.quakeDetailsService.getShakeMapLayer(this.feature.event_id, this.shakeMap.shakemap_source, this.shakeMap.shakemap_version)
    );

    // Add the ShakeMap layer to the layer group
    this.interactions.get(InteractionConstants.SHAKEMAP).miniMapLayerGroup.getLayers().push(
      await this.quakeDetailsService.getShakeMapLayer(this.feature.event_id, this.shakeMap.shakemap_source, this.shakeMap.shakemap_version)
    );

    // Add the vector layer to the layer group
    this.interactions.get(InteractionConstants.SHAKEMAP).mapLayerGroup.getLayers().push(
      new VectorLayer({
        source: new VectorSource({
          features: [this.getEpicentreFeature()]
        }),
        zIndex: 50
      })
    );

    this.interactions.get(InteractionConstants.SHAKEMAP).miniMapLayerGroup.getLayers().push(
      new VectorLayer({
        source: new VectorSource({
          features: [this.getEpicentreFeature()]
        }),
        zIndex: 50
      })
    );

    // Add the layer to the main map and mini map
    if (!this.deviceCommonService.isMobile()) {
      this.mapService.addLayerToMap(this.interactions.get(InteractionConstants.SHAKEMAP).mapLayerGroup);
      this.mapService.showRecentEarthquakesLayer(InteractionConstants.SHAKEMAP, false);
    }
    this.showMiniMapSpinner = false;
    setTimeout(() => {
      this.deviceCommonService.scrollIntoView(this.bottomElementRef);
    }, 600);
    this.ref.markForCheck();

    const bounds: BoundsCommon = new BoundsCommon(...extent);
    if (!this.deviceCommonService.isMobile()) {
      this.mapService.zoomToBoundsWithPadding(bounds, 1500, [100, this.sidePanelCommonService.openPanelWidth, 100, 50]);
    }

    if (this.miniMapComponents.length > 0) {
      this.miniMapComponents.first.miniMapService.addLayerToMap(this.interactions.get(InteractionConstants.SHAKEMAP).miniMapLayerGroup);
      this.miniMapComponents.first.miniMapService.zoomToBoundsWithPadding(bounds, 1500, [20, 0, 20, 0]);
    }

    this.shakemapClickListenerKey = this.mapService.mapInstance.on('singleclick', (event: MapBrowserEvent<UIEvent>) => {
      if (event.dragging || !this.shakeMap.event_published_version) {
        return;
      }

      if (this.quakeDetailsService.isCoordinateWithinShakemap(event.coordinate)) {
        this.zone.run(() => {
          this.quakeDetailsService.displayEventDetails(event.pixel, this.shakeMap);
        });
      }
    });
  }

  // Removes shakemap from the map
  removeShakemap(): void {
    this.mapService.showRecentEarthquakesLayer(InteractionConstants.SHAKEMAP, true);
    this.mapService.removeLayer(this.interactions.get(InteractionConstants.SHAKEMAP).mapLayerGroup);

    if (this.miniMapComponents.length > 0) {
      this.zone.runOutsideAngular(() => {
        this.mapService.mapInstance.removeLayer(this.interactions.get(InteractionConstants.SHAKEMAP).mapLayerGroup);
        this.miniMapComponents.first.miniMapService.mapInstance.removeLayer(this.interactions.get(InteractionConstants.SHAKEMAP).miniMapLayerGroup);
      });
    }

    if (this.shakemapClickListenerKey) {
      unByKey(this.shakemapClickListenerKey);
      delete this.shakemapClickListenerKey;
    }

    delete this.interactions.get(InteractionConstants.SHAKEMAP).mapLayerGroup;
    delete this.interactions.get(InteractionConstants.SHAKEMAP).miniMapLayerGroup;
    this.quakeDetailsService.hideEventDetails();
  }

  // Displays the felt grid on the map
  toggleFeltGrid(): void {
    this.interactions.get(InteractionConstants.FELT_GRID).visible = !this.interactions.get(InteractionConstants.FELT_GRID).visible;

    if (this.interactions.get(InteractionConstants.FELT_GRID).visible) {
      // Add the felt grid layer to the map
      this.autoRefreshCommonService.addAutoRefresh(InteractionConstants.FELT_GRID, () => {
        this.createFeltGrid();
      }, () => {
        if (this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup) {
          return this.isInteractionVisible(InteractionConstants.FELT_GRID);
        }
      });

      this.interactions.get(InteractionConstants.FELT_GRID).autoRefreshOperator = InteractionConstants.FELT_GRID;
    } else {
      this.removeFeltGrid();
    }
  }

  // Displays the felt grid on the map
  createFeltGrid(): void {
    this.removeFeltGrid();
    this.showMiniMapSpinner = true;
    this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup = new Group();
    this.interactions.get(InteractionConstants.FELT_GRID).miniMapLayerGroup = new Group();

    const gridResolutions: [number, number, number][] = [
      [1, 0, 152.8740565703525],
      [5, 152.8740565703525, 305.748113140705],
      [10, 305.748113140705, 611.49622628141],
      [20, 611.49622628141, Infinity]
    ];

    const scale: ScaleQuantize<string> = scaleQuantize<string>()
      .domain([0.5, 10.5])
      .range(['#EFEFEF', '#BECDFF', '#9A98CD', '#8AFEFD', '#7FF798', '#FFFE02', '#FFDC02', '#FF9001', '#FE0000', '#890000']);
    const boundsList: BoundsCommon [] = [];

    for (const [resolution, minResolution, maxResolution] of gridResolutions) {
      this.quakeDetailsService.getFeltGridGeoJSON(this.feature.event_id, resolution).pipe(first()).subscribe((json) => {
        const layerConfig: any = {
          source: new VectorSource({
            features: this.geoJsonFormatter.readFeatures(json, {
              dataProjection: this.environment.displayProjection,
              featureProjection: this.environment.datumProjection
            }),
          }),
          minResolution,
          maxResolution,
          style: (feature: Feature): Style => {
            const colour: string = scale(+feature.getProperties()['intensity']);
            const stroke: RGBColor = rgb(colour);
            const fill: RGBColor = rgb(colour);

            fill.opacity = 0.75;

            return new Style({
              stroke: new Stroke({
                color: stroke.toString(),
                width: 1
              }),
              fill: new Fill({
                color: fill.toString()
              })
            });
          },
          zIndex: 60
        };

        const mapLayer: VectorLayer<VectorSource> = new VectorLayer(layerConfig);
        const miniMapLayer: VectorLayer<VectorSource> = new VectorLayer(layerConfig);

        mapLayer.set('resolution', resolution);
        miniMapLayer.set('resolution', resolution);

        this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup.getLayers().push(mapLayer);
        this.interactions.get(InteractionConstants.FELT_GRID).miniMapLayerGroup.getLayers().push(miniMapLayer);

        boundsList.push(new BoundsCommon(mapLayer.getSource().getExtent()[0], mapLayer.getSource().getExtent()[1], mapLayer.getSource().getExtent()[2], mapLayer.getSource().getExtent()[3]));

        if (boundsList.length === gridResolutions.length) {
          if (!this.deviceCommonService.isMobile()) {
            this.mapService.zoomToBoundsWithPadding(this.geometryCommonService.getMaxExtentFromBounds(boundsList), 1500, [100, this.sidePanelCommonService.openPanelWidth, 100, 50]);
          }

          if (this.miniMapComponents.length > 0) {
            this.miniMapComponents.first.miniMapService.zoomToBoundsWithPadding(this.geometryCommonService.getMaxExtentFromBounds(boundsList), 1500, [20, 0, 20, 0]);
          }
        }
      });
    }

    this.feltGridClickListenerKey = this.mapService.mapInstance.on('singleclick', (event: MapBrowserEvent<UIEvent>) => {
      if (event.dragging) {
        return;
      }

      this.mapService.mapInstance.forEachFeatureAtPixel(event.pixel, (feature: FeatureLike, layer) => {
        if (this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup) {
          this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup.getLayers().forEach((item) => {
            if (layer === item) {
              this.zone.run(() => {
                this.quakeDetailsService.displayFeltGridDetails(event.pixel, feature.getProperties(), layer.get('resolution'));
              });
            }
          });
        }
      });
    });

    // Add the felt grid layer to the map
    if (!this.deviceCommonService.isMobile()) {
      this.mapService.addLayerToMap(this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup);
    }

    // Add the felt grid layer to the mini map
    if (this.miniMapComponents.length > 0) {
      this.miniMapComponents.first.miniMapService.addLayerToMap(this.interactions.get(InteractionConstants.FELT_GRID).miniMapLayerGroup);
    }
    this.showMiniMapSpinner = false;
    setTimeout(() => {
      this.deviceCommonService.scrollIntoView(this.bottomElementRef);
    }, 300);
    this.ref.markForCheck();
  }

  // Removes the felt grid on the map
  private removeFeltGrid(): void {
    this.mapService.showRecentEarthquakesLayer(InteractionConstants.FELT_GRID, true);
    this.zone.runOutsideAngular(() => {
      this.mapService.mapInstance.removeLayer(this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup);
      if (this.miniMapComponents?.length > 0) {
        this.miniMapComponents.first.miniMapService.mapInstance.removeLayer(this.interactions.get(InteractionConstants.FELT_GRID).miniMapLayerGroup);
      }
    });
    this.mapService.removeLayer(this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup);

    // If auto refresh is enabled remove the felt grid from the auto refresh services
    if (this.interactions.get(InteractionConstants.FELT_GRID).autoRefreshOperator) {
      this.autoRefreshCommonService.removeAutoRefresh(this.interactions.get(InteractionConstants.FELT_GRID).autoRefreshOperator);
      delete this.interactions.get(InteractionConstants.FELT_GRID).autoRefreshOperator;
    }

    delete this.interactions.get(InteractionConstants.FELT_GRID).mapLayerGroup;

    if (this.feltGridClickListenerKey) {
      unByKey(this.feltGridClickListenerKey);
      delete this.feltGridClickListenerKey;
    }

    this.quakeDetailsService.hideFeltGridDetails();
  }

  // Create the star for the Shake Map feature
  private getEpicentreFeature(): Feature {
    const epicentre: Feature = new Feature({
      geometry: new Point([this.feature.longitude, this.feature.latitude])
    });

    epicentre.getGeometry().transform(this.environment.displayProjection, this.environment.datumProjection);

    epicentre.setStyle(new Style({
      image: new RegularShape({
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'black', width: 2 }),
        points: 5,
        radius: 10,
        radius2: 4,
        angle: 0
      })
    }));

    return epicentre;
  }

  // Formula to create the ellipse provided by ATWS
  calculateEllipse(longitude: number, latitude: number, semiMajor: number, semiMinor: number, angle: number, steps: number): any[] {
    if (steps == null) {
      steps = 36;
    }

    const points: any[] = [];

    // Angle is given by Degree Value
    const azim: number = angle * (Math.PI / 180); // (Math.PI/180) converts Degree Value into Radians
    const sinazim: number = Math.sin(azim);
    const cosazim: number = Math.cos(azim);

    // Convert latitude to radians
    const latr: number = latitude * (Math.PI / 180);
    const coslat: number = Math.cos(latr);

    // Divide by 111.19(KM per degree)
    semiMajor = semiMajor / 111.19;
    semiMinor = semiMinor / 111.19;

    for (let i: number = 0; i < 360; i += 360 / 36) {
      const alpha: number = i * (Math.PI / 180)
        - azim;
      const sinalpha: number = Math.sin(alpha);
      const cosalpha: number = Math.cos(alpha);

      const X: any = longitude + (semiMajor * sinazim
          * cosalpha + semiMinor * cosazim
          * sinalpha)
        / coslat;
      const Y: any = latitude + (semiMajor * cosazim
        * cosalpha - semiMinor * sinazim
        * sinalpha);

      points.push([X, Y]);
    }

    return points;
  }

  // Return the colour of the feature
  getEarthquakeColour(feature: FeatureType): string {
    return this.mapService.getEarthquakeColour(feature.hours_since_quake);
  }

  // Create the stations layer
  createStationsLayer(url: string): void {
    this.stationsVectorLayer = new VectorLayer({
      source: new VectorSource({
        url: url,
        format: new GeoJSON()
      }),
      style: MapService.getStationStyle,
      visible: true,
      zIndex: 80
    });
    this.zone.runOutsideAngular(() => {
      this.mapService.mapInstance.addLayer(this.stationsVectorLayer);
    });
  }

  // Remove the station layer from the map
  removeStationsLayer(): void {
    this.zone.runOutsideAngular(() => {
      this.mapService.mapInstance.removeLayer(this.stationsVectorLayer);
    });
  }

  // Displays the felt reports on the map
  toggleFeltReports(): void {
    this.interactions.get(InteractionConstants.FELT_REPORTS).visible = !this.interactions.get(InteractionConstants.FELT_REPORTS).visible;

    if (this.interactions.get(InteractionConstants.FELT_REPORTS).visible) {
      this.createFeltReportLayer();
    } else {
      this.removeFeltReportLayer();
    }
  }

  // Create the felt report layer and add an interaction for on hover over the clusters
  createFeltReportLayer(): void {
    this.removeFeltReportLayer();

    QuakeDetailsMapComponent.feltReportMapVectorLayer = new VectorLayer({
      source: new Cluster({
        distance: 40,
        source: new VectorSource({
          url: this.earthQuakeFeltReportsWFSUrl + '&CQL_FILTER=event_id=\'' + this.feature.event_id + '\'',
          format: new GeoJSON()
        })
      }),
      style: this.getFeltReportStyle,
      visible: true
    });

    QuakeDetailsMapComponent.feltReportMiniMapVectorLayer = new VectorLayer({
      source: new Cluster({
        distance: 40,
        source: new VectorSource({
          url: this.earthQuakeFeltReportsWFSUrl + '&CQL_FILTER=event_id=\'' + this.feature.event_id + '\'',
          format: new GeoJSON()
        })
      }),
      style: this.getFeltReportStyle,
      visible: true
    });

    this.zone.runOutsideAngular(() => {
      if (!this.deviceCommonService.isMobile()) {
        this.mapService.mapInstance.addLayer(QuakeDetailsMapComponent.feltReportMapVectorLayer);
      }

      if (this.miniMapComponents.length > 0) {
        this.miniMapComponents.first.miniMapService.mapInstance.addLayer(QuakeDetailsMapComponent.feltReportMiniMapVectorLayer);
      }
    });

    QuakeDetailsMapComponent.feltReportMapVectorLayer.set('id', 'feltQuakeDetailsMap');
    QuakeDetailsMapComponent.feltReportMiniMapVectorLayer.set('id', 'feltQuakeDetailsMiniMap');

    this.feltReportMapInteraction = new Select({
      layers: function(layer: Layer): boolean {
        if (layer.getProperties().id === 'feltQuakeDetailsMap') {
          return true;
        }
      },
      condition: function(evt: any): boolean {
        return evt.type === 'pointermove' ||
          evt.type === 'singleclick';
      },
      style: MapService.selectStyleFunction
    });

    this.feltReportMiniMapInteraction = new Select({
      layers: function(layer: Layer): boolean {
        if (layer.getProperties().id === 'feltQuakeDetailsMiniMap') {
          return true;
        }
      },
      condition: function(evt: any): boolean {
        return evt.type === 'pointermove' ||
          evt.type === 'singleclick';
      },
      style: MapService.selectStyleFunction
    });

    this.zone.runOutsideAngular(() => {
      this.mapService.mapInstance.addInteraction(this.feltReportMapInteraction);

      if (this.miniMapComponents) {
        if (this.miniMapComponents.length > 0) {
          this.miniMapComponents.first.miniMapService.mapInstance.addInteraction(this.feltReportMiniMapInteraction);
        }
      }
    });
  }

  // Remove the felt report layer from the map
  removeFeltReportLayer(): void {
    if (QuakeDetailsMapComponent.feltReportMapVectorLayer) {
      this.zone.runOutsideAngular(() => {
        this.mapService.mapInstance.removeLayer(QuakeDetailsMapComponent.feltReportMapVectorLayer);
        this.mapService.mapInstance.removeInteraction(this.feltReportMapInteraction);
        if (this.miniMapComponents) {
          if (this.miniMapComponents.length > 0) {
            this.miniMapComponents.first.miniMapService.mapInstance.removeLayer(QuakeDetailsMapComponent.feltReportMiniMapVectorLayer);
            this.miniMapComponents.first.miniMapService.mapInstance.removeInteraction(this.feltReportMiniMapInteraction);
          }
        }
      });
    }
  }

  // Sets up styles for the layer
  getFeltReportStyle(feature: FeatureLike): Style {
    QuakeDetailsMapComponent.calculateClusterInfo();

    let clusterStyle: Style;
    const size: any = feature.get('features').length;

    if (size > 1) {
      clusterStyle = new Style({
        image: new Circle({
          radius: feature.get('radius'),
          fill: new Fill({
            color: [0, 255, 255, Math.min(0.8, 0.4 + (size / QuakeDetailsMapComponent.feltReportFeatureCount))]
          })
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({
            color: '#fff'
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.6)',
            width: 3
          })
        })
      });
    } else {
      const originalFeature: any = feature.get('features')[0];
      clusterStyle = MapService.getEarthquakeStyle(originalFeature);
    }

    return clusterStyle;
  }

  // Updates the range to display for the felt report layer in hours
  changeFeltReportRange(): void {
    this.removeFeltReportLayer();
    this.createFeltReportLayer();
  }

  // Resets the map to its original position and zoom level
  resetMap(): void {
    if (!this.interactions.get(InteractionConstants.FELT_REPORTS).visible && !this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).visible) {
      this.zone.runOutsideAngular(() => {
        this.mapService.mapInstance.getView().setZoom(this.zoomLevel);
      });
    }
  }

  toggleStations(showStations: boolean): void {
    // For mobile devices display the stations in the mini map
    if (this.deviceCommonService.isMobile()) {
      if (showStations) {
        this.createStationsLayer(this.stationsURL + '&CQL_FILTER=earthquake_id=' + this.feature.earthquake_id);
      } else if (this.stationsVectorLayer != null) {
        this.removeStationsLayer();
      }
    } else if (this.stationsVectorLayer != null) {
      this.removeStationsLayer();
    }
  }

  // Return the visible property of the interaction
  isInteractionVisible(interaction: string): boolean {
    return this.interactions.get(interaction).visible;
  }

  // Return the enabled property of the interaction
  isInteractionEnabled(interaction: string): boolean {
    return this.interactions.get(interaction).enabled;
  }

  toggleShowShakeMapLegend(): void {
    this.showShakeMapLegend = !this.showShakeMapLegend;
  }

  reset(): void {
    this.showMiniMapSpinner = false;
    this.mapService.removeLayer(this.interactions.get(InteractionConstants.LOCATION_UNCERTAINTY).mapLayer);
    this.mapService.removeLayer(this.interactions.get(InteractionConstants.SHAKEMAP).mapLayerGroup);

    this.removeFeltReportLayer();
    this.removeFeltGrid();

    if (this.miniMapComponents) {
      if (this.miniMapComponents.length > 0) {
        this.zone.runOutsideAngular(() => {
          this.miniMapComponents.first.miniMapService.mapInstance.removeLayer(this.interactions.get(InteractionConstants.SHAKEMAP).miniMapLayerGroup);
        });
      }
    }

    this.initialiseInteractions();

    if (this.sidePanelCommonService.sidePanelRight.activeToolId !== 'search') {
      this.mapService.resetRecentEarthquakesLayer();
    }
  }

  // Business logic if only Felt Grid return Felt Grid, otherwise return ShakeMap
  getLegendText(): string {
    if (!this.isInteractionEnabled(this.constants.SHAKEMAP) && this.isInteractionEnabled(this.constants.FELT_GRID)) {
      return 'Felt Grid';
    } else {
      return 'ShakeMap';
    }
  }

  /*
   * Check if bounds has changed, if not there is no need to apply a timeout as there will be no zoom to bounds
   * If you zoom without a timeout the circle is shown with jagged edges
   */
  getTimeoutForLastBounds(bounds: BoundsCommonType, time: number): number {
    let timeout: number = 0;
    if (this.lastBounds) {
      if (JSON.stringify(this.lastBounds) !== JSON.stringify(bounds)) {
        timeout = time;
      }
    } else {
      timeout = time;
    }
    this.lastBounds = bounds;

    return timeout;
  }

  // OnDestroy reset all the interactions and unsubscribe
  ngOnDestroy(): void {
    this.reset();
    this.subscriptions.unsubscribe();
  }
}

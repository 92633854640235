import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FeatureJson } from '../types/feature.json.type';
import { MapService } from '../map/map.service';
import { ENVIRONMENT, ShareCommonService, SidePanelCommonService, ToolbarCommonType, ToolbarToolsCommonType } from 'flying-hellfish-common';
import { MapSaveStateType } from '../types/map.save.state.type';
import { ApplicationStateService } from '@shared/services/state/application.state.service';
import { NotificationsService } from '../tools/notifications/notifications.service';
import { FeatureType } from '../types/feature.type';

@Component({
  templateUrl: 'main.component.html'
})
export class MainComponent implements AfterViewInit, OnDestroy {
  activeToolRight: string;
  activeToolLeft: string;
  disclaimerUrl: string = this.environment.disclaimerUrl;
  feedbackEmail: string = this.environment.feedbackEmail;
  feedbackEmailSubject: string = this.environment.feedbackEmailSubject;
  toolBarTools: ToolbarToolsCommonType = this.environment.toolbar.tools;
  toolbar: ToolbarCommonType = this.environment.toolbar;
  eventId: string;
  searchUrl: string = this.environment.serviceUrls.earthQuakeSummaryWFS;
  subscriptions: Subscription = new Subscription();

  constructor(private sidePanelCommonService: SidePanelCommonService, private activeRoute: ActivatedRoute, private http: HttpClient,
              private mapService: MapService, public shareCommonService: ShareCommonService, private applicationStateService: ApplicationStateService,
              private notificationsService: NotificationsService, @Inject(ENVIRONMENT) private environment: any) {
    this.subscriptions.add(sidePanelCommonService.leftMenuEmitter.subscribe((data) => {
      this.activeToolLeft = data.activeToolId;
    }));

    this.subscriptions.add(sidePanelCommonService.rightMenuEmitter.subscribe((data) => {
      this.activeToolRight = data.activeToolId;
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.activeRoute.url.subscribe((url) => {
      switch (url[0].path.toLowerCase()) {
        case 'event':
          this.handleEventUrl(url);
          break;
        case 'zoom':
          this.handleZoomUrl(url);
          break;
        case 'restore':
          this.handleRestoreUrl(url);
          break;
        case 'feltreport':
          this.handleFeltReportUrl(url);
          break;
        case 'search':
          this.handleSearchUrl(url);
          break;
        case 'notifications':
          this.handleNotificationsUrl(url);
          break;
        case 'emailsubscription':
          this.handleEmailSubscriptionUrl(url);
          break;
        default:
          console.warn(`Unsupported event url: /${url[0].path}`);
          break;
      }
    }));
  }

  /**
   * Get the earthquake for the event id passed in.
   *
   * @param eventId - the event id
   */
  getEarthquake(eventId: string): Observable<FeatureJson> {
    return this.http.get<FeatureJson>(this.searchUrl + '&CQL_FILTER=event_id=' + '\'' + eventId + '\' AND display_flag=\'Y\'');
  }

  /**
   * Handle /event/:id URL.
   *
   * @param url - the url segments
   */
  private handleEventUrl(url: UrlSegment[]): void {
    if (url.length === 2) {
      this.subscriptions.add(this.activeRoute.params.subscribe(params => {
        this.eventId = params['id'];
        if (this.eventId) {
          this.subscriptions.add(this.getEarthquake(this.eventId)
            .subscribe({
              next: data => {
                const feature: any = data.features[0];
                if (feature) {
                  this.sidePanelCommonService.mapToolClicked({
                    toolId: 'identify',
                    toolSide: 'right',
                    panelWidth: this.sidePanelCommonService.getRightPanelWidthForTool('identify'),
                    keepOpen: true
                  });
                  this.mapService.identifyEventFeatureEmitter.next(feature);
                }
              },
              error: error => console.error(error)
            }));
        }
      }));
    }
  }

  /**
   * Handle /zoom/:level or /zoom/:longitude/:latitude/:level URL.
   *
   * @param url - the url segments
   */
  private handleZoomUrl(url: UrlSegment[]): void {
    if (url.length === 2) {
      this.subscriptions.add(this.activeRoute.params.subscribe(params => {
        this.mapService.setZoomLevel(params['level']);
      }));
    } else if (url.length === 4) {
      this.subscriptions.add(this.activeRoute.params.subscribe(params => {
        this.mapService.zoomToLocation(params['longitude'], params['latitude'], params['level']);
      }));
    }
  }

  /**
   * Handle /restore/:restoreId URL.
   *
   * @param url - the url segments
   */
  private handleRestoreUrl(url: UrlSegment[]): void {
    if (url.length === 2) {
      this.subscriptions.add(this.activeRoute.params.subscribe(params => {
        this.subscriptions.add(this.shareCommonService.restoreApplicationState(params['restoreId']).subscribe((restoreState: MapSaveStateType) => {
          this.applicationStateService.restoreApplicationState(restoreState);
        }));
      }));
    }
  }

  /**
   * Handle /feltreport or /feltreport/:eventId URL.
   *
   * @param url - the url segments
   */
  private handleFeltReportUrl(url: UrlSegment[]): void {
    if (url.length === 1) {
      this.openFeltReportTool();
    } else if (url.length === 2) {
      const eventId: string = url[1].path;

      this.subscriptions.add(this.getEarthquake(eventId).subscribe({
        next: (response) => {
          this.openFeltReportTool(response.features?.[0]?.properties);
        },
        error: (e) => {
          console.error(e);
          this.openFeltReportTool();
        }
      }));
    }
  }

  /**
   * Open the felt report tool in the side panel.
   *
   * @param feature - an optional feature to pass to the felt report tool
   */
  private openFeltReportTool(feature?: FeatureType): void {
    this.sidePanelCommonService.mapToolClicked({
      toolId: 'feltReport',
      toolSide: 'right',
      panelWidth: this.sidePanelCommonService.getRightPanelWidthForTool('feltReport'),
      keepOpen: true
    });

    if (feature) {
      this.mapService.featureEmitter.next(feature);
    }
  }

  /**
   * Handle /search URL.
   *
   * @param url - the url segments
   */
  private handleSearchUrl(url: UrlSegment[]): void {
    if (url.length === 1) {
      this.sidePanelCommonService.mapToolClicked({
        toolId: 'search',
        toolSide: 'right',
        panelWidth: this.sidePanelCommonService.getRightPanelWidthForTool('search'),
        keepOpen: true
      });
    }
  }

  /**
   * Handle /notifications URL.
   *
   * @param url - the url segments
   */
  private handleNotificationsUrl(url: UrlSegment[]): void {
    if (url.length === 1) {
      this.sidePanelCommonService.mapToolClicked({
        toolId: 'notifications',
        toolSide: 'left',
        panelWidth: this.sidePanelCommonService.getLeftPanelWidthForTool('notifications'),
        keepOpen: true
      });
    }
  }

  /**
   * Handle /emailsubscription URL.
   *
   * @param url - the url segments
   */
  private handleEmailSubscriptionUrl(url: UrlSegment[]): void {
    if (url.length === 1) {
      this.sidePanelCommonService.mapToolClicked({
        toolId: 'notifications',
        toolSide: 'left',
        panelWidth: this.sidePanelCommonService.getLeftPanelWidthForTool('notifications'),
        keepOpen: true
      });

      this.notificationsService.emailSubscriptionSubject.next();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

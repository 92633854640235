export interface ShakemapDownloadsType {
  info: string;
  intensityJPG: string;
  intensityPDF: string;
  json: string;
  kml: string;
  kmz: string;
  pgaCont: string;
  pgaJPG: string;
  pgaPDF: string;
  pgvCont: string;
  pgvJPG: string;
  pgvPDF: string;
  raster: string;
  shapeFile: string;
  traces: string;
  vector: string;
}

export const SHAKEMAP_DOWNLOADS_DEFAULT: ShakemapDownloadsType =  {
  info: null,
  intensityJPG: null,
  intensityPDF: null,
  json: null,
  kml: null,
  kmz: null,
  pgaCont: null,
  pgaJPG: null,
  pgaPDF: null,
  pgvCont: null,
  pgvJPG: null,
  pgvPDF: null,
  raster: null,
  shapeFile: null,
  traces: null,
  vector: null
}

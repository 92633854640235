<div [@animationCommonEnter500Leave500]>
  <h4>{{ feature.description }}</h4>
  <div class="title-header">
    <div class="header">Summary</div>
    <table class="table table-responsive table-striped" aria-label="Earthquake Summary">
      <tr class="sr-only">
        <th scope="col">Property</th>
        <th scope="col">Value</th>
        <th scope="col">Property</th>
        <th scope="col">Value</th>
      </tr>
      <tbody>
        <tr>
          <td id="originTimeTitle" class="title" title={{glossary.time}}>Origin (UTC):</td>
          <td id="originTime">{{ feature.origin_time | dateTimeFormat }}</td>
          <td id="epicentralTimeTitle" class="title" title={{glossary.epicentralTime}}>Epicentral Time:</td>
          <td id="epicentralTime">{{ feature.epicentral_time | dateTimeFormat }}</td>
        </tr>
        <tr>
          <td id="longitudeTitle" class="title" title={{glossary.longitude}}>Longitude:</td>
          <td id="longitude">{{ feature.longitude | number:'1.2-2' }}</td>
          <td id="latitudeTitle" class="title" title={{glossary.latitude}}>Latitude:</td>
          <td id="latitude">{{ feature.latitude | number:'1.2-2' }}</td>
        </tr>
        <tr>
          <td id="preferredMagnitudeTitle" class="title" title={{glossary.magnitude}}>Magnitude:</td>
          <td id="preferredMagnitude">{{ feature.preferred_magnitude | number:'1.1-1' }} ({{ feature.preferred_magnitude_type }})</td>
          <td id="depthTitle" class="title" title={{glossary.depth}}>Depth:</td>
          <td id="depth"><span *ngIf="feature.depth !== null">{{ feature.depth | number:'1.0-0' }} km</span></td>
        </tr>
        <tr>
          <td id="eventIdTitle" class="title" title={{glossary.eventId}}>Event Id:</td>
          <td id="eventId">{{ feature.event_id }}</td>
          <td id="feltReportsTitle" class="title" *ngIf="feltReportCount > 0">Felt Reports:</td>
          <td id="feltReports" *ngIf="feltReportCount > 0">{{ feltReportCount }}</td>
          <td *ngIf="feltReportCount === 0"></td>
          <td *ngIf="feltReportCount === 0"></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="title-header" *ngIf="isInteractionEnabled(constants.MAGNITUDE)">
    <div class="header">
      <img [src]="isInteractionVisible(constants.MAGNITUDE) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'"
           alt="Magnitude" class="toggle-icon" (click)="toggleInteraction(constants.MAGNITUDE)"/>Magnitude
    </div>
    <div *ngIf="isInteractionVisible(constants.MAGNITUDE)" [@animationCommonVerticalEnter500Leave500]>
      <div *ngIf="magnitudeError">
        <ng-container *ngTemplateOutlet="loadEarthquakeDetailError;context:{ message: 'Unable to load earthquake magnitude', callbacks: earthquakeDetailErrorCallbacks.magnitude, feature: feature }">
        </ng-container>
      </div>
      <table class="table table-responsive table-striped table-two-column" aria-label="Earthquake Magnitude">
        <tr class="sr-only">
          <th scope="col">Property</th>
          <th scope="col">Value</th>
        </tr>
        <tbody>
          <ng-container *ngFor="let mag of magnitude">
            <ng-container *ngIf="canDisplayMagnitude(mag.properties)">
              <tr *ngIf="mag.properties.type === 'mw'">
                <td id="mwTitle" class="title" title={{glossary.mw}}>Mw:</td>
                <td id="mw"><span *ngIf="checkNumericValue(mag.properties.magnitude)">{{ mag.properties.magnitude | number:'1.1-1' }}
                  <span *ngIf="feature.preferred_magnitude_type === 'Mw'">(Preferred)</span></span></td>
              </tr>
              <tr *ngIf="mag.properties.type === 'mwp'">
                <td id="mwpTitle" class="title" title={{glossary.mwp}}>Mwp:</td>
                <td id="mwp"><span *ngIf="checkNumericValue(mag.properties.magnitude)">{{ mag.properties.magnitude | number:'1.1-1' }}
                  <span *ngIf="feature.preferred_magnitude_type === 'Mwp'">(Preferred)</span></span></td>
              </tr>
              <tr *ngIf="mag.properties.type === 'mwmwp'">
                <td id="mwmwpTitle" class="title" title={{glossary.mwmwp}}>Mw(Mwp):</td>
                <td id="mwmwp"><span *ngIf="checkNumericValue(mag.properties.magnitude)">{{ mag.properties.magnitude | number:'1.1-1' }}
                  <span *ngIf="feature.preferred_magnitude_type === 'Mw(Mwp)'">(Preferred)</span></span></td>
              </tr>
              <tr *ngIf="mag.properties.type === 'mww'">
                <td id="mwwTitle" class="title" title={{glossary.mww}}>Mww:</td>
                <td id="mww"><span *ngIf="checkNumericValue(mag.properties.magnitude)">{{ mag.properties.magnitude | number:'1.1-1' }}
                  <span *ngIf="feature.preferred_magnitude_type === 'Mww'">(Preferred)</span></span></td>
              </tr>
              <tr *ngIf="mag.properties.type === 'mla'">
                <td id="mlaTitle" class="title" title={{glossary.mla}}>MLa:</td>
                <td id="mla"><span *ngIf="checkNumericValue(mag.properties.magnitude)">{{ mag.properties.magnitude | number:'1.1-1' }}
                  <span *ngIf="feature.preferred_magnitude_type === 'MLa'">(Preferred)</span></span></td>
              </tr>
              <tr *ngIf="mag.properties.type === 'mb'">
                <td id="mbTitle" class="title" title={{glossary.mb}}>mb:</td>
                <td id="mb"><span *ngIf="checkNumericValue(mag.properties.magnitude)">{{ mag.properties.magnitude | number:'1.1-1' }}
                  <span *ngIf="feature.preferred_magnitude_type === 'mb'">(Preferred)</span></span></td>
              </tr>
              <tr *ngIf="mag.properties.type === 'ms'">
                <td id="msTitle" class="title" title={{glossary.ms}}>ms:</td>
                <td id="ms"><span *ngIf="checkNumericValue(mag.properties.magnitude)">{{ mag.properties.magnitude | number:'1.1-1' }}
                  <span *ngIf="feature.preferred_magnitude_type === 'ms'">(Preferred)</span></span></td>
              </tr>
              <tr *ngIf="mag.properties.type === 'mb_idc'">
                <td id="mbidcTitle" class="title" title={{glossary.mb_idc}}>mb_IDC(ga):</td>
                <td id="mbidc"><span *ngIf="checkNumericValue(mag.properties.magnitude)">{{ mag.properties.magnitude | number:'1.1-1' }}
                  <span *ngIf="feature.preferred_magnitude_type === 'mb_IDC(ga)'">(Preferred)</span></span></td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div class="title-header" *ngIf="isFeaturePreSeiscomp">
    <div class="header">
      <img [src]="isInteractionVisible(constants.FORMAL) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" class="toggle-icon"
           alt="Formal Uncertainties" (click)="toggleInteraction(constants.FORMAL)"/>Formal Uncertainties
    </div>
    <div *ngIf="isInteractionVisible(constants.FORMAL)" [@animationCommonVerticalEnter500Leave500]>
      <table class="table table-responsive table-striped" aria-label="Formal Uncertainties">
        <tr class="sr-only">
          <th scope="col">Property</th>
          <th scope="col">Value</th>
          <th scope="col">Property</th>
          <th scope="col">Value</th>
        </tr>
        <tbody>
          <tr>
            <td id="originTimeUncertaintyTitle" class="title" title="{{glossary.originTime}}">Origin time:</td>
            <td id="originTimeUncertainty">
              <span *ngIf="checkNumericValue(feature.origin_time_uncertainty)">{{ feature.origin_time_uncertainty | number:'1.2-2' }} sec</span>
            </td>
            <td id="depthUncertaintyTitle" class="title" title={{glossary.depthUncertainty}}>Depth:</td>
            <td id="depthUncertainty">
              <span *ngIf="checkNumericValue(feature.depth_uncertainty)">{{ feature.depth_uncertainty | number:'1.0-0' }} km</span>
            </td>
          </tr>
          <tr>
            <td id="maxHorizontalUncertaintyTitle" class="title">Semi-major axis:</td>
            <td id="maxHorizontalUncertainty">
              <span *ngIf="checkNumericValue(feature.max_horizontal_uncertainty)">{{ feature.max_horizontal_uncertainty | number:'1.0-0' }} km</span>
            </td>
            <td id="minHorizontalUncertaintyTitle" class="title">Semi-minor axis:</td>
            <td id="minHorizontalUncertainty">
              <span *ngIf="checkNumericValue(feature.min_horizontal_uncertainty)">{{ feature.min_horizontal_uncertainty | number:'1.0-0' }} km</span>
            </td>
          </tr>
          <tr>
            <td id="azimuthHorizontalUncertaintyTitle" class="title" title={{glossary.strike}}>Strike angle:</td>
            <td id="azimuthHorizontalUncertainty">
              <span *ngIf="checkNumericValue(feature.azimuth_horizontal_uncertainty)">{{ feature.azimuth_horizontal_uncertainty | number:'1.0-0' }}&deg;</span>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="title-header">
    <div class="header">
      <img [src]="isInteractionVisible(constants.QUALITY) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" class="toggle-icon"
           alt="Quality Indicators" (click)="toggleInteraction(constants.QUALITY)"/>Quality Indicators
    </div>
    <div *ngIf="isInteractionVisible(constants.QUALITY)" [@animationCommonVerticalEnter500Leave500]>
      <table class="table table-striped table-two-column" aria-label="Quality Indicators">
        <tr class="sr-only">
          <th scope="col">Property</th>
          <th scope="col">Value</th>
        </tr>
        <tbody>
          <tr>
            <td id="stationCountTitle" class="title" title={{glossary.stations}}>Time defining stations:</td>
            <td id="stationCount"><span *ngIf="checkNumericValue(feature.station_count)">{{ feature.station_count }}</span>
            </td>
          </tr>
          <tr>
            <td id="phaseCountTitle" class="title" title={{glossary.phases}}>Number of phases used:</td>
            <td id="phaseCount"><span *ngIf="checkNumericValue(feature.phase_count)">{{ feature.phase_count }}</span></td>
          </tr>
          <tr>
            <td id="azimuthalGapTitle" class="title" title={{glossary.azimuth}}>Max azimuthal gap:</td>
            <td id="azimuthalGap">
              <span *ngIf="checkNumericValue(feature.azimuthal_gap)">{{ feature.azimuthal_gap | number:'1.0-0' }}&deg;</span>
            </td>
          </tr>
          <tr>
            <td id="standardErrorTitle" class="title" title={{glossary.rms}}>RMS residual:</td>
            <td id="standardError">
              <span *ngIf="checkNumericValue(feature.standard_error)">{{ feature.standard_error | number:'1.2-2' }} sec</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="title-header">
    <div class="header">
      <img [src]="isInteractionVisible(constants.SOLUTION) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" class="toggle-icon"
           alt="Solution Status" (click)="toggleInteraction(constants.SOLUTION)"/>Solution Status
    </div>
    <div *ngIf="isInteractionVisible(constants.SOLUTION)" [@animationCommonVerticalEnter500Leave500]>
      <table class="table table-responsive table-striped" aria-label="Solution Status">
        <tr class="sr-only">
          <th scope="col">Property</th>
          <th scope="col">Value</th>
          <th scope="col">Property</th>
          <th scope="col">Value</th>
        </tr>
        <tbody>
          <tr>
            <td id="eventModificationTimeTitle" class="title">Updated (UTC):</td>
            <td id="eventModificationTime">{{ feature.event_modification_time | dateTimeFormat }}</td>
            <td id="evaluationStatusTitle" class="title">Status:</td>
            <td id="evaluationStatus">{{ getReviewStatus(feature.evaluation_status) | titlecase }}</td>
          </tr>
          <tr>
            <ng-container *ngIf="!feature.published_version">
              <td id="sourceTitle" class="title">Source:</td>
              <td id="source" colspan="3">{{ feature.source }}</td>
            </ng-container>
            <ng-container *ngIf="feature.published_version">
              <td id="sourceTitle" class="title">Source:</td>
              <td id="source">{{ feature.source }}</td>
              <td id="versionTitle" class="title">Published Version:</td>
              <td id="version">{{ feature.published_version }}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="feature.station_count > 0" [@animationCommonEnter300]>
    <div class="title-header">
      <div class="header" [ngClass]="{'station-header' : !isInteractionVisible(constants.STATIONS)}"><img
        [src]="isInteractionVisible(constants.STATIONS) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" class="toggle-icon"
        alt="Stations" (click)="toggleInteraction(constants.STATIONS)"/>Stations
      </div>
      <div *ngIf="isInteractionVisible(constants.STATIONS)" [@animationCommonVerticalEnter500Leave500]>
        <div *ngIf="stationsError">
          <ng-container *ngTemplateOutlet="loadEarthquakeDetailError;context:{ message: 'Unable to load earthquake stations', callbacks: earthquakeDetailErrorCallbacks.stations, feature: feature }">
          </ng-container>
        </div>
        <div class="table-top" [hidden]="!isInteractionVisible(constants.STATIONS)">
          <div *ngFor="let station of stations">
            <div class="header">{{ getStationTitle(station) }}</div>
            <table class="table table-responsive table-striped table-station" (click)="zoomToStation(station)" aria-label="Stations">
              <tr class="sr-only">
                <th scope="col">Property</th>
                <th scope="col">Value</th>
                <th scope="col">Property</th>
                <th scope="col">Value</th>
              </tr>
              <tbody title="Click to zoom to the station on the map ">
                <tr>
                  <td id="stationCodeTitle" class="title">Station Code:</td>
                  <td id="stationCode">{{ station.properties.station_code }}</td>
                  <td id="stnUtcdateTitle" class="title">Arrival Time (UTC):</td>
                  <td id="stnUtcdate">{{ station.properties.arrival_time | dateTimeFormat }}</td>
                </tr>
                <tr>
                  <td id="phaseTitle" class="title">Phase:</td>
                  <td id="phase">{{ station.properties.phase }}</td>
                  <td id="timedefTitle" class="title">Use of Phase</td>
                  <td id="timedef">{{ station.properties.time_defining_for_origin === 'true' ? 'Defining' : 'Non Defining' }}</td>
                </tr>
                <tr>
                  <td id="azimuthTitle" class="title">Azimuth:</td>
                  <td id="azimuth">{{ station.properties.azimuth_source_to_sta | number:'1.0-0' }}</td>
                  <td id="deltaTitle" class="title">Distance(Km):</td>
                  <td id="delta">{{ station.properties.distance_deg * 111.19 | number:'1.0-0' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="progress" [hidden]="!showExportProgressBar">
            <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
                 aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%">
              Exporting CSV
            </div>
          </div>
          <div class="text-center" *ngIf="stationsLoaded">
            <button id="exportCsvButton" class="btn btn-ga" (click)="exportCsv()">Export Stations CSV</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isInteractionEnabled(constants.FOCAL_MECHANISM)" [@animationCommonVerticalEnter500Leave500]>
    <div class="title-header">
      <div class="header">
        <img [src]="isInteractionVisible(constants.FOCAL_MECHANISM) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" class="toggle-icon"
             alt="Focal Mechanism" (click)="toggleInteraction(constants.FOCAL_MECHANISM)"/>Focal Mechanism
      </div>
      <div *ngIf="isInteractionVisible(constants.FOCAL_MECHANISM)" [@animationCommonVerticalEnter500Leave500]>
        <table class="table table-striped" aria-label="Focal Mechanism">
          <tr class="sr-only">
            <th scope="col">Property</th>
            <th scope="col">Value</th>
            <th scope="col">Property</th>
            <th scope="col">Value</th>
          </tr>
          <tbody>
            <tr>
              <td id="magnitudeTitle" class="title">Magnitude:</td>
              <td id="magnitude">{{ focalMechanism[0].properties.magnitude | number:'1.1-1' }}</td>
              <td id="typeTitle" class="title">Type:</td>
              <td id="type">{{ focalMechanism[0].properties.type }}</td>
            </tr>
            <tr>
              <td id="longitudeTitleFm" class="title">Longitude:</td>
              <td id="longitudeFm">{{ focalMechanism[0].properties.longitude | number:'1.3-3' }}</td>
              <td id="latitudeTitleFm" class="title">Latitude:</td>
              <td id="latitudeFm">{{ focalMechanism[0].properties.latitude | number:'1.3-3' }}</td>
            </tr>
            <tr>
              <td id="azimuhtalGapTitleFm" class="title">Azimuthal Gap:</td>
              <td id="azimuhtalGapFm">{{ focalMechanism[0].properties.azimuthal_gap | number:'1.0-0' }}&deg;</td>
              <td id="depthTitleFm" class="title">Depth:</td>
              <td id="depthFm">{{ focalMechanism[0].properties.depth | number:'1.0-0' }}</td>
            </tr>
            <tr>
              <td id="misfitTitle" class="title">Misfit:</td>
              <td id="misfit">{{ focalMechanism[0].properties.misfit | number:'1.2-2' }}</td>
              <td id="methodTitle" class="title">Method:</td>
              <td id="method">{{ focalMechanism[0].properties.method_id }}</td>
            </tr>
          </tbody>
        </table>
        <div class="table-top">
          <div class="header">Nodal Plane 1</div>
          <table class="table table-responsive table-striped" aria-label="Nodal Plane 1">
            <tr class="sr-only">
              <th scope="col">Property</th>
              <th scope="col">Value</th>
              <th scope="col">Property</th>
              <th scope="col">Value</th>
            </tr>
            <tbody>
              <tr>
                <td id="dipTitle1" class="title">Dip:</td>
                <td id="dip1">{{ focalMechanism[0].properties.dip | number:'1.0-0' }}&deg;</td>
                <td id="rakeTitle1" class="title">Rake:</td>
                <td id="rake1">{{ focalMechanism[0].properties.rake | number:'1.0-0' }}&deg;</td>
              </tr>
              <tr>
                <td id="strikeTitle1" class="title">Strike:</td>
                <td id="strike1">{{ focalMechanism[0].properties.strike | number:'1.0-0' }}&deg;</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-top">
          <div class="header">Nodal Plane 2</div>
          <table class="table table-responsive table-striped" aria-label="Nodal Plane 2">
            <tr class="sr-only">
              <th scope="col">Property</th>
              <th scope="col">Value</th>
              <th scope="col">Property</th>
              <th scope="col">Value</th>
            </tr>
            <tbody>
              <tr>
                <td id="dipTitle2" class="title">Dip:</td>
                <td id="dip2">{{ focalMechanism[1].properties.dip | number:'1.0-0' }}&deg;</td>
                <td id="rakeTitle2" class="title">Rake:</td>
                <td id="rake2">{{ focalMechanism[1].properties.rake | number:'1.0-0' }}&deg;</td>
              </tr>
              <tr>
                <td id="strikeTitle2" class="title">Strike:</td>
                <td id="strike2">{{ focalMechanism[1].properties.strike | number:'1.0-0' }}&deg;</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-top moment-tensor">
          <div class="header">Moment Tensor</div>
          <img src="/moment-tensor/{{feature.event_id}}.png" title="A mathematical representation of the movement on a fault during an earthquake represented here with a beach ball indicating potential fault planes."
               alt="Moment Tensor">
        </div>
      </div>
    </div>
  </div>

  <div class="title-header" *ngIf="isInteractionEnabled(constants.SEISMOGRAM)" [@animationCommonVerticalEnter500Leave500]>
    <div class="header">
      <img [src]="isInteractionVisible(constants.SEISMOGRAM) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" class="toggle-icon"
           alt="Seismograms" (click)="toggleInteraction(constants.SEISMOGRAM)"/>Seismograms
    </div>
    <div *ngIf="isInteractionVisible(constants.SEISMOGRAM)" [@animationCommonVerticalEnter500Leave500]>
      <img src="{{shakeMapDownloads.traces}}" alt="Seismograms" style="width: 100%;">
    </div>
  </div>

  <div class="title-header" *ngIf="isInteractionEnabled(constants.FELT_CHART)" [@animationCommonVerticalEnter500Leave500]>
    <div class="header">
      <img [src]="isInteractionVisible(constants.FELT_CHART) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" class="toggle-icon"
           alt="Felt Report Chart" (click)="toggleInteraction(constants.FELT_CHART)"/>Felt Report Chart
    </div>
    <div *ngIf="isInteractionVisible(constants.FELT_CHART) && !showFeltSpinner" id="feltChart">
      <div *ngIf="feltChartError">
        <ng-container *ngTemplateOutlet="loadEarthquakeDetailError;context:{ message: 'Unable to load felt report chart', callbacks: earthquakeDetailErrorCallbacks.feltChart, feature: feature }">
        </ng-container>
      </div>
      <div *ngIf="!feltChartError">
        <em>This chart represents felt reports received within the first 24 hours of event '{{ feature.event_id }}' occurring. The chart is updated every 10 minutes.</em>
        <div id="feltChartGraph">
          <ga-felt-report-chart
            [gradient]="false"
            [chartsData]="chart.config"
            [xAxis]="true"
            [xAxisTicks]="chart.xAxisTicks"
            [yAxis]="true"
            [yMainAxisLabel]="chart.yAxisLabel"
            [yMainAxisShowLabel]="true"
            [ySecondaryAxisLabel]="'Cumulative felt reports'"
            [ySecondaryAxisShowLabel]="true"
            [autoScale]="true"
            [legend]="true"
            [showXAxisLabel]="true"
            [showYMainGridLines]="false"
            [showYSecondaryGridLines]="false"
            [schemeType]="ordinalScaleType"
            [scheme]="chart.colorScheme"
            [xAxisLabel]="chart.xAxisLabel"
            [xAxisTickFormatting]="formatXAxisTicks"
          ></ga-felt-report-chart>
        </div>
        <!--
          Workaround for this issue https://github.com/swimlane/ngx-charts/issues/1248,
          as the ngx-chart bounding box no longer includes the height of the legend, so add spacer div
          here to make up for the lost height and prevent the next heading from displaying on top of
          the legend
        -->
        <div style="height: 57px;"></div>
      </div>
    </div>
    <div id="loader" *ngIf="showFeltSpinner"></div>
  </div>

  <div class="title-header" [@animationCommonVerticalEnter500Leave500]>
    <div class="header">
      <img [src]="isInteractionVisible(constants.DOWNLOADS) ? 'assets/minus-5-16.png' : 'assets/plus-5-16.png'" class="toggle-icon"
           alt="ShakeMap" (click)="toggleInteraction(constants.DOWNLOADS)"/>ShakeMap, FeltGrid and other downloads
    </div>

    <div *ngIf="isInteractionVisible(constants.DOWNLOADS)" class="downloads" [@animationCommonVerticalEnter500Leave500]>
      <table class="table table-striped" aria-label="Downloads">
        <tr class="sr-only">
          <th scope="col">Property</th>
          <th scope="col">Value</th>
        </tr>
        <tbody>
          <tr>
            <td id="downloadEvents" class="title">
              <label>Earthquake Parameters</label>
              <a (click)="downloadXML('/' + feature.event_id + '.xml', feature.event_id + '.xml')">SeisComP XML</a>
            </td>
          </tr>
          <tr>
            <td id="downloadShakeMapDisclaimer" class="title">
              <label>ShakeMap Disclaimer</label>
              <a href="cache/ShakeMapDisclaimer.pdf" download target="_blank" rel="noopener">PDF</a>
            </td>
          </tr>
          <tr>
            <td id="downloadAbout" class="title">
              <label>About ShakeMap</label>
              <a href="cache/AboutGAShakeMap.pdf" download target="_blank" rel="noopener">PDF</a>
            </td>
          </tr>
          <tr>
            <td id="downloadShakeMap" class="title">
              <label>About Felt Grid</label>
              <a href="cache/WEBPAGE_AboutFeltGrid.pdf" download target="_blank" rel="noopener">PDF</a>
            </td>
          </tr>
          <tr>
            <td id="downloadMMI" class="title">
              <label>About Modified Mercalli Intensity scale (MMI)</label>
              <a href="cache/AboutMMI.pdf" download target="_blank" rel="noopener">PDF</a>
            </td>
          </tr>
          <ng-container *ngIf="!isInteractionEnabled(constants.DOWNLOADS)">
            <tr>
              <td id="noShakeMap" class="title">
                A ShakeMap has not been produced for this earthquake (see About ShakeMap)
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="shakeMap.felt_grid_enabled !== 'Y'">
            <tr>
              <td id="noFeltGrid" class="title">
                A FeltGrid has not been produced for this earthquake (see About FeltGrid)
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="isInteractionEnabled(constants.DOWNLOADS)">
            <tr>
              <td id="downloadIntensityShakeMap" class="title">
                <label>Intensity ShakeMap</label>
                <a href="{{shakeMapDownloads.intensityPDF}}" target="_blank" rel="noopener">PDF</a>
                <a href="{{shakeMapDownloads.intensityJPG}}" target="_blank" rel="noopener">JPG</a>
              </td>
            </tr>
            <tr>
              <td id="downloadPGV" class="title">
                <label>Peak Ground Velocity Contours</label>
                <a href="{{shakeMapDownloads.pgvCont}}" target="_blank" rel="noopener" download>GeoJSON</a>
                <a href="{{shakeMapDownloads.pgvPDF}}" target="_blank" rel="noopener">PDF</a>
                <a href="{{shakeMapDownloads.pgvJPG}}" target="_blank" rel="noopener">JPG</a>
              </td>
            </tr>
            <tr>
              <td id="downloadPGA" class="title">
                <label>Peak Ground Acceleration Contours</label>
                <a href="{{shakeMapDownloads.pgaCont}}" target="_blank" rel="noopener" download>GeoJSON</a>
                <a href="{{shakeMapDownloads.pgaPDF}}" target="_blank" rel="noopener">PDF</a>
                <a href="{{shakeMapDownloads.pgaJPG}}" target="_blank" rel="noopener">JPG</a>
              </td>
            </tr>
            <tr>
              <td id="downloadShapefileContours" class="title">
                <label>Shapefile Contours</label>
                <a href="{{shakeMapDownloads.vector}}" target="_blank" rel="noopener" download>ShapeFile</a>
              </td>
            </tr>
            <tr *ngIf="shakeMapDownloads.json || shakeMapDownloads.kml">
              <td id="downloadWFS" class="title">
                <label>Modified Mercalli Intensity Contours</label>
                <a href="{{shakeMapDownloads.json}}" download>GeoJSON</a>
                <a href="{{shakeMapDownloads.kml}}" download>KML</a></td>
            </tr>
            <tr>
              <td id="downloadKMZ" class="title">
                <label>Raster Formats</label>
                <a href="{{shakeMapDownloads.raster}}" target="_blank" rel="noopener">Esri Grid</a>
                <a *ngIf="shakeMapDownloads.kmz" href="{{shakeMapDownloads.kmz}}" download>KMZ</a>
                <a href="assets/shakemap_legend.png" target="_blank" rel="noopener">Legend</a>
              </td>
            </tr>
            <tr>
              <td id="downloadMetadata" class="title">
                <label>Metadata</label>
                <a href="{{shakeMapDownloads.info}}" target="_blank" rel="noopener">JSON</a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <ga-quake-details-map [feature]="feature" [shakeMap]="shakeMap"></ga-quake-details-map>
</div>

<ng-template #loadEarthquakeDetailError let-message="message" let-feature="feature" let-callbacks="callbacks">
  <br>
  <fh-alert class="earthquake-detail-error" type="error">
    <h3><b>{{ message }}</b></h3>
    <p>
      Sorry, we can't do that right now due to a technical or network issue.
      You can close this message and continue viewing the rest of the earthquake details, or try to load again.
      If this error persists please
      <a href="mailto:earthquakes&#64;ga.gov.au?subject={{ message }} for event_id {{ feature.event_id }}" target="_blank" rel="noopener">
        contact Earthquakes&#64;GA.
      </a>
    </p>
    <span class="earthquake-detail-error-buttons">
      <button (click)="callbacks.onClose()" class="btn btn-ga close-error">Close</button>
      <button (click)="callbacks.onRetry()" class="btn btn-ga retry-action">Try again</button>
    </span>
  </fh-alert>
</ng-template>

<div id="popup" class="ol-popup" #popup>
  <div>
    <a class="ol-popup-closer" (click)="closePopup()"><img src="assets/x-mark-16-white.png" title="Close" alt="Close" height="12" width="12"></a>
  </div>
  <div id="popup-content">
    <div class="popup">Event Parameters</div>
    <table class="table table-striped" aria-label="Event Parameters">
      <tr class="sr-only">
        <th scope="col">Property</th>
        <th scope="col">Value</th>
      </tr>
      <tbody>
        <tr>
          <td class="title">Event Id:</td>
          <td>{{ shakemapReponse?.event_id }}</td>
        </tr>
        <tr>
          <td class="title">Origin Time (UTC):</td>
          <td>{{ shakemapReponse?.origin_time | dateTimeFormat }}</td>
        </tr>
        <tr>
          <td class="title">Latitude:</td>
          <td>{{ shakemapReponse?.latitude | number:'1.2-2' }}</td>
        </tr>
        <tr>
          <td class="title">Longitude:</td>
          <td>{{ shakemapReponse?.longitude | number:'1.2-2' }}</td>
        </tr>
        <tr>
          <td class="title">Magnitude:</td>
          <td>{{ shakemapReponse?.magnitude | number:'1.1-1' }} ({{ shakemapReponse?.magnitude_type }})</td>
        </tr>
        <tr>
          <td class="title">Depth:</td>
          <td>{{ shakemapReponse?.depth | number:'1.0-0' }} km</td>
        </tr>
        <tr>
          <td class="title">Published Version:</td>
          <td>{{ shakemapReponse?.published_version }}</td>
        </tr>
        <tr>
          <td class="title">Source:</td>
          <td>{{ shakemapReponse?.source }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
